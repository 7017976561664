import BokehPlot from "./BokehPlot";
import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import DataTable from "./DataTable";
import {
    Box,
    CircularProgress,
    Tooltip
} from '@mui/material';
import { SlShareAlt } from "react-icons/sl";
import Button from "./buttons/Button";
import ShareDashboardModal from './ShareDashboardModal';

function Report({ id, name }) {
    const [plotData, setPlotData] = useState(null);
    const [Loadingerror, setLoadingError] = useState(null);
    const [ArchiveBarplot, setArchiveBarplot] = useState(null);
    const location = useLocation();
    const { institutionID, institutionName } = location.state || {};
    const sid = institutionID || id;
    const instname = institutionName || name;
    const [ArchiveTable, setArchiveTable] = useState(null);
    const [ChampionTable, setChampionTable] = useState(null);
    const [ChampionConsortiumTable, setChampionConsortiumTable] = useState(null);
    const [championTablesChecked, setChampionTablesChecked] = useState(false);
    const [Loading, setLoading] = useState(true);
    const navigate = useNavigate();

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [currentUser, setCurrentUser] = useState(null);
    const [isManager, setIsManager] = useState(false);

    let apiUrl;
    const environment = process.env.REACT_APP_ENV;
    switch (environment) {
        case 'LOCAL':
            apiUrl = process.env.REACT_APP_API_Local_URL;
            break;
        case 'DEV':
            apiUrl = process.env.REACT_APP_API_URL;
            break;
        case 'PRD':
            apiUrl = process.env.REACT_APP_API_URL;
    }

    const handleOpenModal = () => setIsModalOpen(true);
    const handleCloseModal = () => setIsModalOpen(false);

    const handleNavigateToManagement = () => {
        console.log("sid ", sid);
        navigate('/membershipreports/management', { state: { institutionID: sid, institutionName: instname } });
    };

    const handleSendInvitation = async (email, givenName, familyName, jobTitle) => {
        try {
            const response = await fetch(`${apiUrl}/grantAccess?inst_id=${sid}&email=${email}&inst_name=${instname}&given_name=${givenName}&family_name=${familyName}&job_title=${jobTitle}`, {
                method: 'GET',
                credentials: 'include'
            });

            if (!response.ok) {
                throw new Error('Failed to send invitation');
            }

            console.log(`Invitation sent successfully to ${email}`);
            return true; // Indicate success
        } catch (error) {
            console.error("Error sending invitation:", error);
            throw error; // Re-throw the error for the modal to handle
        }
    };

    const fetchManagerStatus = async () => {
        try {
            const response = await fetch(`${apiUrl}/isManager?sid=${sid}`, {
                method: 'GET',
                credentials: 'include'
            });
            if (response.ok) {
                const data = await response.json();
                setIsManager(data.isManager);
            } else {
                console.error('Failed to fetch manager status');
            }
        } catch (error) {
            console.error('Error fetching manager status:', error);
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            setLoadingError(null);
            setLoading(true); // Set loading true here as well

            try {
                // console.log('fetching')
                const response = await fetch(`${apiUrl}/retrieve/${sid}`, {
                    method: 'GET',
                    credentials: 'include'  // cookie
                });
                if (response.status === 403) {
                    console.error('403 Forbidden: Access is denied.');
                    navigate('/membershipreports/report');
                    return;
                }
                const data = await response.json();
                const chartData = data.barplot;
                const tableData = JSON.parse(data.membertable);

                if (chartData !== "") {
                    chartData.forEach((item) => {
                        if (item['report-type'] === "archive-year-count-barplot") {
                            setArchiveBarplot(item.chart);
                            console.log(item.chart);
                        }
                    });
                }

                if (tableData !== "") {
                    const archiveTableItem = tableData.find(item => item['report-type'] === "archive-year-count-table");
                    if (archiveTableItem) {
                        setArchiveTable(archiveTableItem.table);
                    }
                }

                // Process champion tables in worker
                const championTableData = tableData.filter(item =>
                    item['report-type'] === "champion-article-table" ||
                    item['report-type'] === "champion-consortium-article-table"
                );

                if (championTableData.length > 0) {
                    const tableWorker = new Worker(new URL('./tableWorker.js', import.meta.url));
                    tableWorker.postMessage({ tableData: championTableData });

                    tableWorker.onmessage = (event) => {
                        const { championTable, championConsortiumTable, error, tablesProcessed } = event.data;
                        if (error) {
                            setLoadingError(error);
                        } else {
                            if (championTable) setChampionTable(championTable);
                            if (championConsortiumTable) setChampionConsortiumTable(championConsortiumTable);
                        }
                        if (tablesProcessed) {
                            setChampionTablesChecked(true);
                            tableWorker.terminate();
                        }
                    };
                } else {
                    setChampionTablesChecked(true);
                }

            } catch (error) {
                console.log(plotData);
                console.error('Error fetching diagram:', error);
                setLoadingError("The file does not exist");
            } finally {
                setLoading(false);
            }
        };
        fetchData();
    }, [apiUrl, sid, navigate, plotData]);

    useEffect(() => {
        const fetchCurrentUser = async () => {
            try {
                const response = await fetch(`${apiUrl}/getuser`, {
                    method: 'GET',
                    credentials: 'include'
                });
                if (response.ok) {
                    const userData = await response.json();
                    console.log(userData);
                    setCurrentUser(userData);
                    fetchManagerStatus();
                } else {
                    console.error('Failed to fetch user data');
                }
            } catch (error) {
                console.error('Error fetching user data:', error);
            }
        };

        fetchCurrentUser();
    }, [apiUrl, sid]);

    if (Loading) {
        return <Box
            sx={{
                position: 'fixed',   // cover all screen
                top: 0,
                left: 0,
                width: '100%',
                height: '100vh',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: 'rgba(255, 255, 255, 0.7)', // white background
            }}
        >
            <CircularProgress />
        </Box>;
    }

    if (Loadingerror) {
        return <p>Error loading the plot: {Loadingerror}</p>;
    }

    // if(!ArchiveBarplot&&!ArchiveTable&&!ChampionTable&&!ChampionConsortiumTable){
    //     return <p>No available data for now</p>
    // }

    return (
        <div>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Button onClick={() => navigate(-1)} text="← Back" />
                <Box sx={{ display: 'flex', gap: 2 }}>
                    {(isManager || currentUser?.isAdmin) && (
                        <Button onClick={handleNavigateToManagement} text="Manage Members" />
                    )}
                    <Tooltip title="Share this dashboard with other users within your organization">
                        <span>
                            <Button
                                onClick={handleOpenModal}
                                text={
                                    <span>
                                        <SlShareAlt style={{ marginRight: '5px' }} />
                                        Share
                                    </span>
                                }
                            />
                        </span>
                    </Tooltip>
                </Box>
            </Box>

            <ShareDashboardModal
                isOpen={isModalOpen}
                onClose={handleCloseModal}
                onSendInvitation={handleSendInvitation}
                instname={instname}
                apiUrl={apiUrl}
            />

            <h1>Submission Statistics for {instname}</h1>
            <p>Thank you for being an arXiv member! This information is provided as a benefit of membership for your
                organization and should not be shared publicly. Questions about the data? Please see our <a
                    href="https://info.arxiv.org/about/submission_data_faq.html" target="_blank"
                    rel="noopener noreferrer"> FAQ</a>.</p>
            <BokehPlot data={ArchiveBarplot} title={'Articles by Subject'} plotId={'archive-year-count-barplot'} />

            {/* Archive Table */}
            <DataTable data={ArchiveTable} title={'Article Count by Subject'} target_id={'archive-year-count-table'} key={'archive-year-count-table'} />

            {/* Champion Tables */}
            {!championTablesChecked ? (
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <CircularProgress />
                </Box>
            ) : (
                <>
                    {ChampionTable && <DataTable data={ChampionTable} title={'Champion Article Listing'} target_id={'champion-article-table'} key={'champion-article-table'} />}
                    {ChampionConsortiumTable && <DataTable data={ChampionConsortiumTable} title={'Champion Article Listing'} target_id={'champion-consortium-article-table'} key={'champion-consortium-article-table'} />}
                </>
            )}
            Data provided by <img width="44" style={{ verticalAlign: 'middle' }} src="https://arxiv.org/scopus.png" alt="arXiv logo" />

            <h2 id="member-resources">Member Resources</h2>
            <ul>
                <li><a href="https://info.arxiv.org/about/membership.html">Membership program information</a></li>
                {/*<li><a href="https://info.arxiv.org/about/membership_confirm.html">Renew your membership or update your contact information</a></li>*/}
                {/*<li><a href="https://static.arxiv.org/static/arxiv.marxdown/0.1/about/arXiv-payment-info.pdf">Payment instructions (pdf)</a></li>*/}
                <li><a href="mailto:membership@arxiv.org">Questions? We're happy to help</a></li>
            </ul>
        </div>
    );
}

export default Report;
