import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import MediaCard from './memberCard'
import CardGrid from "./cardList";
import { Box, CircularProgress } from '@mui/material';
import { useNavigate, useLocation, redirect } from 'react-router-dom';
import Report from "./reportCharts";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import CardActions from "@mui/material/CardActions";
import Button from "./buttons/Button";
import Card from "@mui/material/Card";


// import DataTable from 'datatables.net-dt';

// export default MyDataTable;



function Selection() {

    const [institutionList, setInstitutionList] = useState([]);
    const [plotData, setPlotData] = useState(null);
    const [loadingerror, setLoadingerror] = useState(null);
    const [loading, setLoading] = useState(false);
    const [currentUser, setCurrentUser] = useState(null);
    const navigate = useNavigate();
    const location = useLocation();
    const [hasAccess, setHasList] = useState(true);
    const [cyclic403, setCyclic403] = useState(false);
    //const  apiUrl  = process.env.REACT_APP_API_DEV_URL;
    let apiUrl
    const environment = process.env.REACT_APP_ENV

    switch (environment) {
        case 'LOCAL':
            apiUrl = process.env.REACT_APP_API_Local_URL;
            break;
        case 'DEV':
            apiUrl = process.env.REACT_APP_API_URL;
            break;
        case 'PRD':
            apiUrl = process.env.REACT_APP_API_URL;
    }

    let baseUrl, membersUrl
    if (environment === 'LOCAL' || environment === 'DEV') {
        baseUrl = 'https://dev.arxiv.org';
        membersUrl = 'https://members.dev.arxiv.org';
    } else {
        baseUrl = 'https://arxiv.org';
        membersUrl = 'https://members.arxiv.org';
    }

    //console.log(environment)
    //console.log(`${apiUrl}/getinstitutions`)

    useEffect(() => {
        const sourceURL = document.referrer
        console.log("source url:", sourceURL)
        const fetchData = async () => {
            setLoading(true)

            try {
                // Fetch current user data
                const userResponse = await fetch(`${apiUrl}/getuser`, {
                    method: 'GET',
                    credentials: 'include'
                });
                if (!userResponse.ok) {
                    throw new Error('Failed to fetch user data');
                }
                const userData = await userResponse.json();
                console.log(userData);
                setCurrentUser(userData);

                // Fetch institutions
                const response = await fetch(`${apiUrl}/getinstitutions`, {
                    method: 'GET',
                    credentials: 'include'  // cookie
                });

                if (response.status === 403 && ['https://members.dev.arxiv.org/', 'https://members.arxiv.org/'].includes(sourceURL)) {
                    setCyclic403(true)
                }

                const institutions = await response.json();
                //console.log(institutions)
                // console.log(idList)
                if (!institutions || institutions.length === 0) {
                    // if the user do not have institutions
                    setHasList(false)
                } else {
                    setHasList(true)
                    setInstitutionList(institutions);
                    // console.log(institutions);
                    // console.log(institutionList.length)
                }


            } catch (error) {
                console.error('Error fetching data:', error);
                // setHasList(false);
                setInstitutionList(null); // Set the data to null in case of errors
            } finally {
                setLoading(false)
            }
        };
        fetchData();
    }, [apiUrl]);

    if (loading) {
        return <Box
            sx={{
                position: 'fixed',   // coverall screen
                top: 0,
                left: 0,
                width: '100%',
                height: '100vh',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: 'rgba(255, 255, 255, 0.7)', // white background
            }}
        >
            <CircularProgress />
        </Box>
    }

    if (!hasAccess) {
        if (cyclic403) {
            window.location.href = baseUrl
        }
        return (
            <React.Fragment>
                <div>
                    <h1>Please log in to access your membership dashboard.</h1>
                </div>
            </React.Fragment>
        )
    }

    console.log(institutionList)

    if (!institutionList) {
        return (<div>
            <h1>Please login to view your member dashboard.</h1>
        </div>)
    }



    if (institutionList.length === 1) {
        const institution = institutionList[0]
        const instID = institution.institution_id
        const instName = institution.institution_name
        return (
            <div>
                <Report id={instID} name={instName} />
            </div>
        )
    }


    return (
        <React.Fragment>
            <div>
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                }}>
                    <div>
                        <h1>Institution Reports</h1>
                        {/*<div style={buttonContainerStyle}>*/}
                        <h2>Please select the report you want to see</h2>

                    </div>
                    {currentUser && currentUser.isAdmin && (
                        <Button onClick={() => navigate('/membershipreports/admin')} text="Admin View" />
                    )}
                </Box>
                <div>
                    <CardGrid institutions={institutionList} />
                </div>

            </div>
        </React.Fragment>
    );
}

export default Selection;
