import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  CircularProgress,
  TextField,
  InputAdornment,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Tabs,
  Tab,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import MemberTable from './MemberTable';
import Button from "./buttons/Button";

const Admin = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentUser, setCurrentUser] = useState(null);
  const [allUsers, setAllUsers] = useState([]);
  const [allInvitations, setAllInvitations] = useState([]);
  const [institutions, setInstitutions] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedInstitution, setSelectedInstitution] = useState('');
  const [orderBy, setOrderBy] = useState('');
  const [order, setOrder] = useState('asc');
  const [activeTab, setActiveTab] = useState(0);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [invitationToRevoke, setInvitationToRevoke] = useState(null);

  const userColumns = ['User ID', 'Family Name', 'Given Name', 'Email', 'Title', 'Institutions', 'ROR ID', 'Is Admin', 'Is Manager', 'Actions'];
  const invitationColumns = ['Family Name', 'Given Name', 'Email', 'Title', 'Institution', 'ROR ID', 'Invited', 'Actions'];

  let apiUrl;
  const environment = process.env.REACT_APP_ENV;
  switch (environment) {
    case 'LOCAL':
      apiUrl = process.env.REACT_APP_API_Local_URL;
      break;
    case 'DEV':
    case 'PRD':
      apiUrl = process.env.REACT_APP_API_URL;
      break;
    default:
      apiUrl = process.env.REACT_APP_API_URL;
  }


  useEffect(() => {
    const fetchAllData = async () => {
      try {
        setLoading(true);

        // Fetch current user data
        const userResponse = await fetch(`${apiUrl}/getuser`, {
          method: 'GET',
          credentials: 'include'
        });
        if (!userResponse.ok) {
          throw new Error('Failed to fetch user data');
        }
        const userData = await userResponse.json();
        console.log(userData);
        setCurrentUser(userData);

        // Only fetch other data if user is authorized
        if (userData.isAdmin) {
          await Promise.all([fetchUsers(), fetchInvitations()]);
        }

        setLoading(false);
      } catch (err) {
        console.error('Error fetching data:', err);
        setError(err.message);
        setLoading(false);
      }
    };

    fetchAllData();
  }, [apiUrl]);

  const fetchUsers = async () => {
    const response = await fetch(`${apiUrl}/getAllUsers`, {
      method: 'GET',
      credentials: 'include'
    });

    if (!response.ok) {
      throw new Error('Failed to fetch users data');
    }

    const userData = await response.json();

    const uniqueEmailInstitutions = new Set();
    const processedUsers = userData.reduce((acc, user) => {
      const emailInstitutionKey = `${user.email}-${user.institution}`;
      if (!uniqueEmailInstitutions.has(emailInstitutionKey)) {
        uniqueEmailInstitutions.add(emailInstitutionKey);
        acc.push({
          'Family Name': user.last_name,
          'Given Name': user.first_name,
          'Email': user.email,
          'Title': user.suffix_name || 'N/A',
          'Institutions': user.institution,
          'ROR ID': user.ror_id || 'N/A',
          'Is Admin': user.isAdmin ? 'Yes' : 'No',
          'Is Manager': user.can_manage ? 'Yes' : 'No',
          'user_id': user.user_id,
          'institution_id': user.institution_id
        });
      }
      return acc;
    }, []);

    setAllUsers(processedUsers);

    const uniqueInstitutions = Array.from(new Set(userData.map(user => user.institution)));
    setInstitutions(['All Institutions', ...uniqueInstitutions]);
  };

  const fetchInvitations = async () => {
    const response = await fetch(`${apiUrl}/getAllInvitations`, {
      method: 'GET',
      credentials: 'include'
    });

    if (!response.ok) {
      throw new Error('Failed to fetch invitations data');
    }

    const invitationsData = await response.json();

    const processedInvitations = invitationsData.map(invitation => ({
      'Family Name': invitation.family_name,
      'Given Name': invitation.given_name,
      'Email': invitation.email,
      'Title': invitation.job_title || 'N/A',
      'Institution': invitation.institution_name,
      'ROR ID': invitation.ror_id || 'N/A',
      'Invited': formatDate(invitation.requested_at),
      'id': invitation.id,
      'institution_id': invitation.institution_id
    }));

    setAllInvitations(processedInvitations);
  };

  const formatDate = (dateString) => {
    if (!dateString) return '';
  
    const date = new Date(dateString);
    const localDate = new Date(date.getTime() - (date.getTimezoneOffset() * 60000));
  
    return localDate.toLocaleString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      hour12: true
    });
  };

  const handlePromoteToManager = async (user) => {
    try {
      const url = new URL(`${apiUrl}/promoteToManager`);
      url.searchParams.append('user_id_to_promote', user.user_id);
      url.searchParams.append('sid', user.institution_id);

      const response = await fetch(url, {
        method: 'GET',
        credentials: 'include',
      });

      if (!response.ok) {
        throw new Error('Failed to promote user to manager');
      }

      console.log(`${user['Given Name']} ${user['Family Name']} promoted to manager successfully`);

      // Update the local state
      const updatedUsers = allUsers.map(u =>
        u['Email'] === user['Email'] ? { ...u, 'Is Manager': 'Yes' } : u
      );
      setAllUsers(updatedUsers);

    } catch (error) {
      console.error("Error promoting user to manager:", error);
    }
  };

  const handleDemoteManager = async (user) => {
    try {
      const url = new URL(`${apiUrl}/demoteManager`);
      url.searchParams.append('user_id_to_demote', user.user_id);
      url.searchParams.append('sid', user.institution_id);

      const response = await fetch(url, {
        method: 'GET',
        credentials: 'include',
      });

      if (!response.ok) {
        throw new Error('Failed to demote user from manager');
      }

      console.log(`${user['Given Name']} ${user['Family Name']} demoted from manager successfully`);

      // Update the local state
      const updatedUsers = allUsers.map(u =>
        u['Email'] === user['Email'] ? { ...u, 'Is Manager': 'No' } : u
      );
      setAllUsers(updatedUsers);

    } catch (error) {
      console.error("Error demoting user from manager:", error);
    }
  };

  const handleRemoveAccess = async (userId) => {
    try {
      const user = allUsers.find(u => u.user_id === userId);
      if (!user) {
        throw new Error('User not found');
      }

      const response = await fetch(`${apiUrl}/removeMemberAccess?user_id_to_remove=${userId}&sid=${user.institution_id}`, {
        method: 'GET',
        credentials: 'include',
      });

      if (!response.ok) {
        throw new Error('Failed to remove member access');
      }

      console.log(`Access removed successfully for user ID: ${userId}`);

      // Update the local state
      const updatedUsers = allUsers.filter(u => u.user_id !== userId);
      setAllUsers(updatedUsers);

    } catch (error) {
      console.error('Error removing member access:', error);
    }
  };

  const handleResendInvitation = async (row) => {
    try {
      const response = await fetch(`${apiUrl}/resendInvite?email=${row.Email}&sid=${row.institution_id}`, {
        method: 'GET',
        credentials: 'include'
      });

      if (!response.ok) {
        throw new Error('Failed to resend invitation');
      }

      console.log("Invitation resent successfully to:", row.Email);

      const updatedInvitations = allInvitations.map(invitation =>
        invitation.id === row.id
          ? { ...invitation, 'Invited': formatDate(new Date().toISOString()) }
          : invitation
      );
      setAllInvitations(updatedInvitations);

      return true;
    } catch (error) {
      console.error("Error resending invitation:", error);
      return false;
    }
  };

  const handleOpenConfirmDialog = (invitation) => {
    setInvitationToRevoke(invitation);
    setConfirmDialogOpen(true);
  };

  const handleCloseConfirmDialog = () => {
    setConfirmDialogOpen(false);
    setInvitationToRevoke(null);
  };

  const handleConfirmRevoke = async () => {
    if (invitationToRevoke) {
      try {
        const response = await fetch(`${apiUrl}/revokeInvitation?email=${invitationToRevoke.Email}&sid=${invitationToRevoke.institution_id}`, {
          method: 'GET',
          credentials: 'include',
        });

        if (!response.ok) {
          throw new Error('Failed to revoke invitation');
        }

        console.log(`Invitation revoked successfully for: ${invitationToRevoke.Email}`);

        const updatedInvitations = allInvitations.filter(invitation => invitation.id !== invitationToRevoke.id);
        setAllInvitations(updatedInvitations);
      } catch (error) {
        console.error('Error revoking invitation:', error);
      }
    }
    handleCloseConfirmDialog();
  };


  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleInstitutionChange = (event) => {
    setSelectedInstitution(event.target.value);
  };

  const handleSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const filteredUsers = allUsers.filter(user =>
    (user['Given Name'].toLowerCase().includes(searchTerm.toLowerCase()) ||
      user['Family Name'].toLowerCase().includes(searchTerm.toLowerCase()) ||
      user['Email'].toLowerCase().includes(searchTerm.toLowerCase())) &&
    (selectedInstitution === '' || selectedInstitution === 'All Institutions' || user['Institutions'] === selectedInstitution)
  );

  const filteredInvitations = allInvitations.filter(invitation =>
    (invitation['Given Name'].toLowerCase().includes(searchTerm.toLowerCase()) ||
      invitation['Family Name'].toLowerCase().includes(searchTerm.toLowerCase()) ||
      invitation['Email'].toLowerCase().includes(searchTerm.toLowerCase()) ||
      invitation['Institution'].toLowerCase().includes(searchTerm.toLowerCase())) &&
    (selectedInstitution === '' || selectedInstitution === 'All Institutions' || invitation['Institution'] === selectedInstitution)
  );

  const sortedUsers = React.useMemo(() => {
    if (!orderBy) return filteredUsers;
    return [...filteredUsers].sort((a, b) => {
      const aValue = a[orderBy];
      const bValue = b[orderBy];
      if (aValue < bValue) return order === 'asc' ? -1 : 1;
      if (aValue > bValue) return order === 'asc' ? 1 : -1;
      return 0;
    });
  }, [filteredUsers, order, orderBy]);

  const handleExportCSV = (data, filename) => {
    // Define the exact order of columns for the CSV export
    const csvColumns = filename === "all_users.csv" 
      ? ['user_id', 'Family Name', 'Given Name', 'Email', 'Title', 'Institutions', 'ROR ID', 'Is Admin', 'Is Manager']
      : Object.keys(data[0]).filter(key => key !== 'institution_id' && key !== 'id');
  
    // Helper function to escape and quote CSV fields
    const escapeField = (field) => {
      if (field == null) return '';
      return '"' + String(field).replace(/"/g, '""') + '"';
    };
  
    const csvContent = [
      csvColumns.map(escapeField).join(','),
      ...data.map(row => csvColumns.map(header => escapeField(row[header] || row[header.toLowerCase()])).join(','))
    ].join('\n');
  
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);
  
    const link = document.createElement("a");
    link.setAttribute("href", url);
    link.setAttribute("download", filename);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleExportUsers = () => {
    handleExportCSV(sortedUsers, "all_users.csv");
  };

  const handleExportInvitations = () => {
    handleExportCSV(filteredInvitations, "all_invitations.csv");
  };


  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '70vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return <Typography color="error">{error}</Typography>;
  }

  if (!currentUser || !currentUser.isAdmin) {
    return <Typography color="error">You are not authorized to view this page.</Typography>;
  }

  return (
    <Box sx={{ padding: 3 }}>
      <Typography variant="h1" sx={{ mb: 3 }}>Admin View</Typography>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
        <Tabs value={activeTab} onChange={handleTabChange} sx={{ mb: 2 }}>
          <Tab label="All Users" />
          <Tab label="All Invitations" />
        </Tabs>
        <Button
          onClick={activeTab === 0 ? handleExportUsers : handleExportInvitations}
          text="Export CSV"
        />
      </Box>

      <Box sx={{ display: 'flex', gap: 2, mb: 3, mt: 3 }}>
        <TextField
          fullWidth
          variant="outlined"
          placeholder={activeTab === 0 ? "Search users..." : "Search invitations..."}
          value={searchTerm}
          onChange={handleSearch}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
        <FormControl fullWidth>
          <InputLabel id="institution-select-label">Institution</InputLabel>
          <Select
            labelId="institution-select-label"
            id="institution-select"
            value={selectedInstitution}
            label="Institution"
            onChange={handleInstitutionChange}
          >
            {institutions.map((institution, index) => (
              <MenuItem key={index} value={institution}>
                {institution}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>

      {activeTab === 0 && (
        <MemberTable
          title="All Users with Access"
          data={sortedUsers}
          columns={userColumns}
          orderBy={orderBy}
          order={order}
          onSort={handleSort}
          isInvitationTable={false}
          isAdminTable={true}
          isAllInvitationsTable={false}
          onPromoteToManager={handlePromoteToManager}
          onDemoteManager={handleDemoteManager}
          onRemoveAccess={handleRemoveAccess}
          onSave={setAllUsers}
        />
      )}

      {activeTab === 1 && (
        <MemberTable
          title="All Pending Invitations"
          data={filteredInvitations}
          columns={invitationColumns}
          orderBy={orderBy}
          order={order}
          onSort={handleSort}
          isInvitationTable={true}
          isAdminTable={true}
          isAllInvitationsTable={true}
          onResendInvitation={handleResendInvitation}
          onDelete={handleOpenConfirmDialog}
          onSave={setAllInvitations}
        />
      )}

      <Dialog
        open={confirmDialogOpen}
        onClose={handleCloseConfirmDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Confirm Invitation Revocation"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to revoke the invitation for {invitationToRevoke?.Email}? This will permanently delete all invitation records associated with this email, and the action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseConfirmDialog} text="Cancel" />
          <Button onClick={handleConfirmRevoke} text="Confirm Revoke" autoFocus />
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default Admin;