import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Button,
  Tooltip,
} from '@mui/material';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';

const baseColumnWidths = {
  'Family Name': '20%',
  'Given Name': '20%',
  'Email': '20%',
  'Title': '15%',
  'Is Manager': '15%',
  'Actions': '20%',
};

const invitationColumnWidths = {
  'Family Name': '20%',
  'Given Name': '20%',
  'Email': '20%',
  'Title': '15%',
  'Invited': '15%',
  'Actions': '20%',
};

const inheritedColumnWidths = {
  'Family Name': '15%',
  'Given Name': '15%',
  'Email': '15%',
  'Title': '15%',
  'Consortium Name': '20%',
  'Actions': '10%',
};

const adminColumnWidths = {
  'User ID': '8%',
  'Family Name': '10%',
  'Given Name': '10%',
  'Email': '14%',
  'Title': '6%',
  'Institutions': '15%',
  'ROR ID': '10%',
  'Is Admin': '8%',
  'Is Manager': '8%',
  'Actions': '9%',
};

const allInvitationsColumnWidths = {
  'Family Name': '12%',
  'Given Name': '12%',
  'Email': '15%',
  'Title': '8%',
  'Institution': '18%',
  'ROR ID': '15%',
  'Invited': '15%',
  'Actions': '8%',
};

const MemberTable = ({
  title,
  data,
  columns,
  onSave,
  onDelete,
  onResendInvitation,
  onRemoveAccess,
  isInvitationTable,
  isInheritedTable,
  isAllInvitationsTable,
  isAdminTable,
  onPromoteToManager,
  onDemoteManager,
  institutionID,
  apiUrl
}) => {
  const [editedData, setEditedData] = useState(data);
  const [orderBy, setOrderBy] = useState('');
  const [order, setOrder] = useState('asc');

  const navigate = useNavigate();

  useEffect(() => {
    setEditedData(data);
  }, [data]);

  const getColumnWidths = () => {
    if (isAdminTable && isAllInvitationsTable) return allInvitationsColumnWidths;
    if (isAdminTable) return adminColumnWidths;
    if (isInvitationTable) return invitationColumnWidths;
    if (isInheritedTable) return inheritedColumnWidths;
    return baseColumnWidths;
  };

  const columnWidths = getColumnWidths();

  const getColumnWidth = (column) => {
    return columnWidths[column] || 'auto';
  };

  const formatDate = (dateString) => {
    if (!dateString) return '';

    const date = new Date(dateString);

    if (isNaN(date.getTime())) {
      console.error('Invalid date:', dateString);
      return 'Invalid Date';
    }

    return date.toLocaleString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      hour12: true
    });
  };

  const handleDelete = (index) => {
    const rowToDelete = editedData[index];
    onDelete(rowToDelete);
  };

  const handleResendInvitation = async (index) => {
    try {
      const row = editedData[index];
      if (onResendInvitation) {
        const success = await onResendInvitation(row);
        if (success) {
          // Create a new row with updated 'Invited' date in ISO format
          const newRow = {
            ...row,
            'Invited': new Date().toISOString()
          };
          // Add the new row at the beginning of the data
          const newData = [newRow, ...editedData];
          setEditedData(newData);
          if (onSave) {
            onSave(newData);
          }
        }
      }
    } catch (error) {
      console.error("Error resending invitation:", error);
      // TODO: show an error message to the user
    }
  };

  const handleRemoveAccess = async (userId) => {
    try {
      await onRemoveAccess(userId);

      // Remove the user from the local state
      const newData = editedData.filter(member => member.user_id !== userId);
      setEditedData(newData);
      onSave(newData);

      console.log(`Member access removed successfully for user ID: ${userId}`);
    } catch (error) {
      console.error('Error removing member access:', error);
      // TODO: Show an error message to the user
    }
  };

  const handleManagerToggle = async (row) => {
    if (isInheritedTable) return;
    const newManagerStatus = row['Is Manager'] === 'No';
    const updatedRow = { ...row, 'Is Manager': newManagerStatus ? 'Yes' : 'No' };

    try {
      if (newManagerStatus) {
        await onPromoteToManager(updatedRow);
      } else {
        await onDemoteManager(updatedRow);
      }

      const newData = data.map(item =>
        item['Email'] === row['Email'] ? updatedRow : item
      );
      onSave(newData);
    } catch (error) {
      console.error('Error toggling manager status:', error);
      // TODO: Show an error message to the user
    }
  };


  const handleSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    const newOrder = isAsc ? 'desc' : 'asc';
    setOrder(newOrder);
    setOrderBy(property);

    const sortedData = [...editedData].sort((a, b) => {
      let aValue, bValue;

      if (property === 'User ID') {
        aValue = parseInt(a.user_id);
        bValue = parseInt(b.user_id);
      } else if (property === 'Is Manager') {
        // Fix Is Manager sorting by directly comparing the string values
        aValue = a[property];
        bValue = b[property];
      } else if (property === 'Invited') {
        aValue = new Date(a[property]).getTime();
        bValue = new Date(b[property]).getTime();
      } else {
        aValue = a[property];
        bValue = b[property];
      }

      // Handle null/undefined values
      if (aValue === undefined || aValue === null) aValue = '';
      if (bValue === undefined || bValue === null) bValue = '';

      if (aValue < bValue) return newOrder === 'asc' ? -1 : 1;
      if (aValue > bValue) return newOrder === 'asc' ? 1 : -1;
      return 0;
    });

    setEditedData(sortedData);
  };

  const getSortIcon = (column) => {
    if (orderBy === column) {
      return order === 'asc' ? <ArrowUpwardIcon fontSize="small" /> : <ArrowDownwardIcon fontSize="small" />;
    }
    return <UnfoldMoreIcon fontSize="small" />;
  };

  const handleNavigateToManagement = (institutionID, institutionName) => {
    navigate('/membershipreports/management', {
      state: { institutionID, institutionName },
    });
  };

  const filteredColumns = isInheritedTable
    ? columns.filter(column => column !== 'Is Manager')
    : columns;

  return (
    <Box sx={{ mb: 4, bgcolor: 'transparent', borderRadius: 1, overflow: 'hidden' }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <h2 style={{ color: '#b31b1b' }}>{title}</h2>
      </Box>
      <TableContainer component={Paper} sx={{ boxShadow: 'none' }}>
        <Table>
          <TableHead>
            <TableRow>
              {filteredColumns.map((column) => (
                <TableCell
                  key={column}
                  onClick={() => column !== 'Actions' && handleSort(column)}
                  sx={{
                    width: getColumnWidth(column),
                    fontWeight: 'bold',
                    borderBottom: '2px solid rgba(224, 224, 224, 1)',
                    cursor: column !== 'Actions' ? 'pointer' : 'default',
                    '&:hover': {
                      backgroundColor: column !== 'Actions' ? 'rgba(0, 0, 0, 0.04)' : 'transparent',
                    },
                    padding: '16px',
                  }}
                >
                  <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <span>{column}</span>
                    {column !== 'Actions' && getSortIcon(column)}
                  </Box>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {editedData.map((row, index) => (
              <TableRow key={index}>
                {filteredColumns.map((column) => (
                  <TableCell
                    key={column}
                    sx={{
                      width: getColumnWidth(column),
                      borderBottom: '1px solid rgba(224, 224, 224, 0.5)',
                      ...(column === 'Is Manager' && { padding: '6px 16px 6px 12px' })
                    }}
                  >
                    {column === 'User ID' ? (
                      row.user_id
                    ) : column === 'Email' ? (
                      <a href={`mailto:${row[column]}`}>
                        {row[column]}
                      </a>
                    ) : column === 'Institutions' || (isAllInvitationsTable && column === 'Institution') ? (
                      <a
                        onClick={() => handleNavigateToManagement(row.institution_id, row[column])}
                        style={{ cursor: 'pointer' }}
                      >
                        {row[column]}
                      </a>
                    ) : column === 'ROR ID' ? (
                      row[column] !== 'N/A' ? (
                        <a
                          href={`${row[column]}`}
                          target="_bl
                            color: '#0366d6', 
                            cursor: 'pointer' ank"
                          rel="noopener noreferrer"
                          style={{
                            textDecoration: 'underline',
                          }}
                        >
                          {row[column]}
                        </a>
                      ) : (
                        row[column]
                      )
                    ) : column === 'Is Manager' ? (
                      <Tooltip title={row['Is Manager'] === 'Yes'
                        ? `Demote ${row['Given Name']} ${row['Family Name']} from manager role.`
                        : `Promote ${row['Given Name']} ${row['Family Name']} to manager role.`}>
                        <IconButton onClick={() => handleManagerToggle(row)} size="small">
                          {row['Is Manager'] === 'Yes' ? (
                            <CheckBoxIcon sx={{ fontSize: 18 }} />
                          ) : (
                            <CheckBoxOutlineBlankIcon sx={{ fontSize: 18 }} />
                          )}
                        </IconButton>
                      </Tooltip>
                    ) : column === 'Invited' ? (
                      formatDate(row[column])
                    ) : column === 'Actions' ? (
                      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                        {isInvitationTable ? (
                          <>
                            <Tooltip title={`Resend the invitation email to ${row['Email']}.`}>
                              <Button
                                onClick={() => handleResendInvitation(index)}
                                variant="outlined"
                                size="small"
                                sx={{
                                  fontSize: '0.7rem',
                                  padding: '1px 6px',
                                  minWidth: 'auto',
                                  textTransform: 'none'
                                }}
                              >
                                Resend
                              </Button>
                            </Tooltip>
                            <Tooltip title={`Revoke the invitation for ${row['Given Name']} ${row['Family Name']} (${row['Email']}).`}>
                              <Button
                                onClick={() => handleDelete(index)}
                                variant="outlined"
                                size="small"
                                sx={{
                                  fontSize: '0.7rem',
                                  padding: '1px 6px',
                                  minWidth: 'auto',
                                  textTransform: 'none'
                                }}
                              >
                                Revoke
                              </Button>
                            </Tooltip>
                          </>
                        ) : (
                          <Tooltip title={`Remove ${row['Given Name']} ${row['Family Name']}'s (${row['Email']}) access. They will no longer be able to view or interact with any institution's data.`}>
                            <Button
                              onClick={() => onRemoveAccess(row.user_id)}
                              variant="outlined"
                              size="small"
                              sx={{
                                fontSize: '0.7rem',
                                padding: '1px 6px',
                                minWidth: 'auto',
                                textTransform: 'none'
                              }}
                            >
                              Remove Access
                            </Button>
                          </Tooltip>
                        )}
                      </Box>
                    ) : (
                      row[column]
                    )}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};
export default MemberTable;
